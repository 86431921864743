import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Navigation from './pages/Navigation';
import EmployeeLogin from './pages/EmployeeLogin';
import AdminLogin from './pages/AdminLogin';

import 'react-calendar/dist/Calendar.css';
import './App.css';


function App() {
  return (
    <main>
        <Switch>
          <Route path="/" component={Navigation} exact />
          <Route path="/employees" component={EmployeeLogin} />
          <Route path="/admins" component={AdminLogin} />
          <Redirect from="*" to="/" />
        </Switch>
    </main>
  );
}

export default App;
