import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { adminHeaders } from '../utils/Requests';

const JobsTab = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isJobLoading, setIsJobLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [jobName, setJobName] = useState('');
  const [jobNumber, setJobNumber] = useState('');


  const fetchJobs = () => {
    setIsLoading(true);
    fetch('/api/jobs', {
      method: 'GET',
      headers: adminHeaders()
    })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .then((json) => {
      setJobs(json);
    })
    .catch(error => {
      setErrorMessage("There was an issue fetching job data, please refresh the page or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }
  // fetch all jobs on mount
  useEffect(() => {
    fetchJobs();
  }, []);

  const deleteJob = (jobId) => {
    setIsLoading(true);
    fetch(`/api/job/${jobId}`, {
      method: 'PUT',
      headers: adminHeaders()
    })
    .then(res => {
      if (res.ok) {
        setJobs(jobs.filter(job => job.job_pk !== jobId));
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .catch(error => {
      setErrorMessage("There was an issue deleting this job, please try again or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }

  const submitJob = () => {
    setIsJobLoading(true);
    fetch(`/api/job`, {
      method: 'POST',
      headers: adminHeaders(),
      body: JSON.stringify({
        job_name: jobName,
        job_number: Number(jobNumber)
      })
    })
    .then(res => {
      if (res.ok) {
        fetchJobs();
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .catch(error => {
      setErrorMessage("There was an issue deleting this job, please try again or contact the administrator.")
    })
    .finally(() => setIsJobLoading(false));
  }

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Saving...</span>
      </Spinner>
    );
  }

  return (
    <>
      <p>{errorMessage}</p>
      <h3>Add a new job</h3>
      <Form className="jobEntryForm mb-5">
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>Job Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Job Name"
              onChange={(e) => setJobName(e.target.value)}
              value={jobName}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridNumber">
            <Form.Label>Job Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Job Number"
              onChange={(e) => setJobNumber(e.target.value)}
              value={jobNumber}
            />
          </Form.Group>
        </Row>
        <Button
          variant="primary"
          onClick={() => submitJob()}
          disabled={(!jobName || !jobNumber || isJobLoading)}
        >
          {isJobLoading ?
            <Spinner animation="border" role="status">
              <span className="sr-only">Saving...</span>
            </Spinner>
            :
            <div>Save</div>
          }
        </Button>
      </Form>
      <h3 className='mb-3'>All Active Jobs</h3>
      {jobs.map((job) => {
        return (
          <div key={job.job_pk} className="jobs">
            <Button
              variant="outline-danger"
              className="jobsDeleteButton mb-2"
              onClick={() => deleteJob(Number(job.job_pk))}
              disabled={isLoading}
            >
              Delete
            </Button>
            <p>{job.name} ({job.number})</p>
            <hr/>
          </div>
        )
      })}
    </>
  );
}

export default JobsTab;