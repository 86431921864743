import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import * as moment from 'moment'
import { adminHeaders } from '../utils/Requests';
import { secondsToHms } from '../utils/Helpers';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';


function CalendarTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [entries, setEntries] = useState({});
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(moment().day("Thursday").add(-1, 'week'));

  useEffect(() => {
    getTimeEntriesForWeek(firstDayOfWeek);
  }, []);

  const cleanEntryData = (entriesResponse) => {
    const cleanedEntries = {};
    if (entriesResponse.length === 0) {
      setEntries({});
    } else {
      entriesResponse.forEach((entry) => {
        const {
          first_name,
          last_name,
        } = entry;
        const fullName = first_name + ' ' + last_name;
        if (!cleanedEntries[fullName]) {
          cleanedEntries[fullName] = { userEntries: [], totalHours: 0}
        }

        cleanedEntries[fullName].userEntries.push(entry);
      });

      // count total hours for the user that week
      for (const [key] of Object.entries(cleanedEntries)) {
        const totalHours = cleanedEntries[key].userEntries.reduce((accumulator, currentEntry) => {
          return accumulator + currentEntry.hours;
        }, 0)

        cleanedEntries[key].totalHours = totalHours;
      }

      setEntries(cleanedEntries);
    }
  }

  const getTimeEntriesForWeek = (day) => {
    setIsLoading(true);
    const startDate = moment(day).format('YYYY-MM-DD');
    const endDate = moment(day).add(7, 'days').format('YYYY-MM-DD');

    fetch(`/api/entries?startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: adminHeaders()
    })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .then((json) => {
      cleanEntryData(json);
    })
    .catch(error => {
      setErrorMessage("There was an issue getting this week's entries, please try again or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }
  const onWeekSelected = (weekNum) => {
    const firstDayOfWeek = moment().day("Thursday").week(weekNum + 1);
    setFirstDayOfWeek(firstDayOfWeek);

    getTimeEntriesForWeek(firstDayOfWeek);
  }

  const onCopyToCSV = async (entries) => {
    setIsCopying(true);
    const firstDayOfWeekDate = firstDayOfWeek.toDate();
    firstDayOfWeekDate.setHours(0);
    firstDayOfWeekDate.setMinutes(0);
    firstDayOfWeekDate.setSeconds(0);
    firstDayOfWeekDate.setMilliseconds(0);

    // the hashmap is keyed by the unix timestamp of each day
    const daysHash = {
      [moment(firstDayOfWeekDate).unix()]: [],
      [moment(firstDayOfWeekDate).add(1, 'days').unix()]: [],
      [moment(firstDayOfWeekDate).add(2, 'days').unix()]: [],
      [moment(firstDayOfWeekDate).add(3, 'days').unix()]: [],
      [moment(firstDayOfWeekDate).add(4, 'days').unix()]: [],
      [moment(firstDayOfWeekDate).add(5, 'days').unix()]: [],
      [moment(firstDayOfWeekDate).add(6, 'days').unix()]: [],
    };

    entries.forEach(({date_created, hours}) => {
      const unixDateCreated = new Date(date_created).setHours(0,0,0,0) / 1000;
      // push hours ex: 27000 seconds = 7.5 hours
      daysHash[unixDateCreated].push(hours / 3600);
    })

    // Find the date with the most time entries
    const mostEntriesUnix = Object.keys(daysHash).reduce((a, b) => daysHash[a].length > daysHash[b].length ? a : b);
    const numIterations = daysHash[mostEntriesUnix].length;

    // loop the amount of times that is the most job entries on a day
    // this will determine the number of rows, using the /n newline character.
    // if a date does not have that index in it's array, it means they have no more
    // entries for that day, just insert an empty column
    let csvString = '';
    for (let i = 0; i < numIterations; i++) {
      for (let x in daysHash) {
        csvString += daysHash[x][i] !== undefined ? daysHash[x][i] : '';
        csvString += ',';
      }

      csvString += '\n';
    }

    try {
      await navigator.clipboard.writeText(csvString);
      console.log(`Copied csv to clipboard: ${csvString}`);
      alert('Copied CSV to clipboard.');

    } catch (err) {
      alert('Failed to copy: ', err);
      console.error('Failed to copy: ', err);
    }
    setIsCopying(false);
  }

  const renderEntries = () => {
    if (isLoading) {
      return <Spinner animation="border" role="status"><span className="sr-only">Saving...</span></Spinner>;
    }

    if (errorMessage) {
      return <p>{errorMessage}</p>
    }

    if (Object.keys(entries).length === 0) {
      return <p>There have no been any entries for this week yet!</p>
    }
  
    return Object.keys(entries).map((key, i) => {
      const {
        totalHours,
        userEntries
      } = entries[key];
      return (
        <div key={key}>
          <h4>{key} ({secondsToHms(totalHours)} total)</h4>
          <Button
              size="sm"
              variant="outline-success"
              onClick={() => onCopyToCSV(userEntries)}
              disabled={isCopying}
            >
              Copy to CSV
            </Button>
          {
            userEntries.map((e) => (
              <div key={e.time_entry_pk}>
                <div className='mt-3'>{new Date(e.date_created).toDateString()}:</div>
                <div>{e.name} ({e.number}) - {secondsToHms(e.hours)}</div>
                <small>{e.description}</small>
              </div>
            ))
          }
          <hr/>
        </div>
      )
    });
  }

  return (
    <>
      <Calendar
        className='mb-3'
        showWeekNumbers={true}
        onClickWeekNumber={onWeekSelected}
        tileDisabled={() => true}
        tileClassName={({ date, view }) => view === 'month' && date > moment(firstDayOfWeek).add(-1, 'days') && date < moment(firstDayOfWeek).add(6, 'days') ? 'selectedDay' : null}
      />
      {renderEntries()}
    </>
  );
}
export default CalendarTab;