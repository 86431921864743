const isAuthenticated = () => {
  return window.localStorage.getItem('authentication') !== null;
}

const setAuthenticated = (password) => {
  window.localStorage.setItem('authentication', password);
}

const removeAuthenticated = () => {
  window.localStorage.removeItem('authentication');
}

const getAuthenticated = () => {
  return window.localStorage.getItem('authentication');
}

const isEmpAuthenticated = () => {
  return window.localStorage.getItem('emp_authentication') !== null && getUserData() !== undefined && getUserData !== null;
}

const setEmpAuthenticated = (password) => {
  window.localStorage.setItem('emp_authentication', password);
}

const getEmpAuthenticated = () => {
  return window.localStorage.getItem('emp_authentication');
}

const removeEmpAuthenticated = () => {
  window.localStorage.removeItem('emp_authentication');
}

const setUserData = (data) => {
  window.localStorage.setItem('user', JSON.stringify(data));
}

const getUserData = () => {
  return JSON.parse(window.localStorage.getItem('user'));
}

const removeUserData = () => {
  window.localStorage.removeItem('user');
}

export {
    isAuthenticated,
    setAuthenticated,
    removeAuthenticated,
    getAuthenticated,
    isEmpAuthenticated,
    setEmpAuthenticated,
    removeEmpAuthenticated,
    getEmpAuthenticated,
    setUserData,
    getUserData,
    removeUserData
}