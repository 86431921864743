import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import * as moment from 'moment'
import Spinner from 'react-bootstrap/Spinner';

import { empHeaders } from '../utils/Requests';
import EntryDialog from './EntryDialog';
import { getUserData } from '../utils/Authentication'


const EmployeeCalendar = () => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [entries, setEntries] = useState([]);
  const [totalDayHours, setTotalDayHours] = useState('');
  const [isEntryDialogOpen, setEntryDialogOpen] = useState(false);
  const handleClose = () => setEntryDialogOpen(false);
  const handleShow = () => setEntryDialogOpen(true);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // fetch all jobs on load
  useEffect(() => {
    setIsLoading(true);
    fetch('/api/jobs', {
      method: 'GET',
      headers: empHeaders()
    })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .then((json) => {
      setJobs(json);
    })
    .catch(error => {
      alert("There was an issue fetching data for this application, please refresh the page or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }, []);

  // fetch entry each time selectedDate is changed
  useEffect(() => {
    setIsLoading(true);
    const userId = getUserData()['user_pk'];
    const formattedDate = moment(selectedDay).format('YYYY-MM-DD');

    fetch(`/api/user/${userId}/entries/${formattedDate}`, {
      method: 'GET',
      headers: empHeaders()
    })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .then((json) => {
      setEntries(json);
    })
    .catch(error => {
      alert("There was an issue fetching data for the selected date, please select a new day or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }, [selectedDay]);

  // calculate total hours in day
  useEffect(() => {
    const totalSeconds = entries.reduce((accumulator, currentEntry) => {
      return accumulator + currentEntry.hours;
    }, 0);

    const hours = moment.utc(totalSeconds*1000).format('H');
    const minutes = moment.utc(totalSeconds*1000).format('m');
    const pluralHours = (hours === 0 || hours > 1) ? 'hours' : 'hour';

    setTotalDayHours(`${hours} ${pluralHours} and ${minutes} minutes`);
  }, [entries]);

  const deleteEntry = (entryPk) => {
    fetch(`/api/entry/${entryPk}`, {
      method: 'DELETE',
      headers: empHeaders()
    })
    .then(res => {
      if (res.ok) {
        setEntries(entries.filter(entry => entry.time_entry_pk !== entryPk));
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .catch(error => {
      alert("There was an issue deleting this entry, please try again or contact the administrator.")
    })
    .finally(() => setIsLoading(false));
  }

  const renderAllEntries = () => {
    return (
      entries.map((entry) => {
        const hours = moment.utc(entry.hours*1000).format('H');
        const minutes = moment.utc(entry.hours*1000).format('m');
        const pluralHours = (hours === 0 || hours > 1) ? 'hours' : 'hour';
        const time = `${hours} ${pluralHours} and ${minutes} minutes`;
        
        return (
          <div key={entry.time_entry_pk}>
            <b>{entry.job_name} (#{entry.job_number})</b>
            <Button
              variant="outline-danger"
              className="calendarEntryDeleteButton"
              onClick={() => deleteEntry(entry.time_entry_pk)}
            >
              Delete
            </Button>
            <div>Time logged: {time}</div>
            <small>{entry.description}</small>
            <hr/>
          </div>
        )
      })
    )
  }

  const renderEntries = () => {
    if (isLoading) {
      return <Spinner animation="border" role="status"><span className="sr-only">Saving...</span></Spinner>;
    }

    if (entries === undefined || entries.length === 0) {
      return <p>There are no entries for this date yet!</p>;
    }

    return renderAllEntries();
  }

  return (
    <>
      <Calendar className='mt-3'
        onChange={setSelectedDay}
        value={selectedDay}
        tileDisabled={({ date }) => date > new Date()}
      />
      <h3 className='mt-3'>{selectedDay.toDateString()}</h3>
      <Button
        variant="outline-primary"
        onClick={() => handleShow()}
        disabled={isLoading}
        className="mb-3"
      >
        Add a new entry
      </Button>
      {(entries !== undefined || entries.length !== 0) &&
        <>
          <h3>Total time logged for day:</h3>
          <b>{totalDayHours}</b>
          <hr/>
        </>
      }
      {renderEntries()}
      <EntryDialog
        jobs={jobs}
        date={selectedDay}
        show={isEntryDialogOpen}
        handleClose={() => handleClose()}
        handleSubmit={() => window.location.reload()}
      />
    </>
  );
}

export default EmployeeCalendar;