import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <div className="center-component bordered">
      <Link to="/admins" className='ml-2 mr-3'>Admins</Link>
      <Link to="/employees" className='mr-2'>Employees</Link>
    </div>
  );
}

export default Navigation;