import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TimePicker from 'react-bootstrap-time-picker';
import Spinner from 'react-bootstrap/Spinner';
import * as moment from 'moment'

import { empHeaders } from '../utils/Requests';
import { getUserData } from '../utils/Authentication'

const EntryDialog = ({date, show, handleClose, handleSubmit, jobs}) => {
  const [selectedJob, setSelectedJob] = useState(0);
  const [selectedHours, setSelectedHours] = useState(0);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onCloseDialog = () => {
    setSelectedHours(0)
    setSelectedJob(0)
    setDescription('')
    setIsLoading(false)
    setErrorMessage('')
    handleClose()
  }

  const onSubmit = () => {
    if (selectedJob === 0) {
      setErrorMessage("*Please fill in all mandatory data*");
    } else {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      const dateObj = moment(date).format("YYYY-MM-DD HH:mm:ss");

      setErrorMessage('');
      setIsLoading(true);
      fetch('/api/entry', {
        method: 'POST',
        headers: empHeaders(),
        body: JSON.stringify({
          job_fk: selectedJob,
          hours: selectedHours,
          description: description,
          user_fk: getUserData()['user_pk'],
          date_created: dateObj
        })
      })
      .then(res => {
        if (res.ok) {
          onCloseDialog();
          handleSubmit();
        } else {
          throw Error('Something went wrong, try again.');
        }
      })
      .catch(error => {
        setErrorMessage(error.toString());
      })
      .finally(() => setIsLoading(false));
    }
  }

  return (
    <Modal show={show} onHide={onCloseDialog}>
        <Modal.Header closeButton>
            <Modal.Title>Submit a new job ({date.toDateString()})</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Label>Job *</Form.Label>
            <Form.Control
              aria-label="Select a job"
              as="select"
              defaultValue={selectedJob}
              onChange={(e) => setSelectedJob(Number(e.target.value))}
            >
              <option key={0} value={0}>{"Select a job"}</option>
              {jobs.map((j) => (
                <option key={j.job_pk} value={j.job_pk}>{j.name}</option>
              ))}
            </Form.Control>

            <Form.Label className='mt-3'>Hours</Form.Label>
            <TimePicker
              format={24}
              start="00:00"
              end="23:59"
              step={30}
              onChange={(time) => setSelectedHours(time)}
              value={selectedHours}
            />
            
            <Form.Label className='mt-3'>Description (optional)</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
            />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={()=> onCloseDialog()} variant="secondary">Close</Button>
          <Button
            onClick={onSubmit}
            variant="primary"
            disabled={isLoading}
          >
            {isLoading ? 
              <Spinner animation="border" role="status">
                <span className="sr-only">Saving...</span>
              </Spinner>
              :
              <>Submit Job</>
            }
          </Button>
        </Modal.Footer>
        <p className='ml-3'>{errorMessage}</p>
    </Modal>
  );
}

export default EntryDialog;