import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Calendar from '../components/Calendar';

import { removeUserData, setUserData, setEmpAuthenticated, isEmpAuthenticated, removeEmpAuthenticated } from '../utils/Authentication'

function EmployeeLogin() {
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmployeeAuthenticated, setIsEmployeeAuthenticated] = useState(false);

  // skip the login screen if they have entered the password previously
  useEffect(() => {
    if (isEmpAuthenticated()) {
      setIsEmployeeAuthenticated(true);
    }
  }, []);

  const logout = () => {
    removeEmpAuthenticated();
    removeUserData();
    setIsEmployeeAuthenticated(false);
  }

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    const data = {
      firstName: firstName,
      lastName: lastName,
      password: password
    };

    fetch('/api/login/employee', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw Error('Something went wrong, try again.');
      }
    })
    .then((data) => {
      setEmpAuthenticated(password);
      setUserData(data);
      setIsEmployeeAuthenticated(true);
    })
    .catch(error => {
      setErrorMessage(error.toString());
    })
    .finally(() => setIsLoading(false));
  };

  return (
    <>
      { isEmployeeAuthenticated && 
        <Button
        className='ml-5 mt-5'
          variant="outline-primary"
          onClick={() => logout()}
        >
          Logout
        </Button>
      }
      <Container>
        <Row>
          <Col className='mt-1' xs={12} lg={{ span: 6, offset: 3 }}>
            <h2>Time Tracking Panel</h2>
            { isEmployeeAuthenticated ?
              <Calendar />
            :
              <Form className='bordered' onSubmit={submit}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="fNameForm"
                    type="text"
                    placeholder="First Name"
                    required
                    onChange={e => setFirstName(e.target.value)} 
                  />
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="lNameForm"
                    type="text"
                    placeholder="Last Name"
                    required
                    onChange={e => setLastName(e.target.value)} 
                  />
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    id="PasswordForm"
                    type="password"
                    placeholder="Password"
                    required
                    onChange={e => setPassword(e.target.value)} 
                  />
                  <Form.Text className="text-muted">
                    Please reach out to the site admin if there are any problems.
                  </Form.Text>
                </Form.Group>
                <Button disabled={isLoading} variant="primary" type="submit">
                  {isLoading ? 
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>Enter</>
                  }
                  </Button>
                  <p>{errorMessage}</p>
              </Form>
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EmployeeLogin;