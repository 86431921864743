import { getEmpAuthenticated, getAuthenticated } from './Authentication'

const empHeaders = () => {
  const empAuth = getEmpAuthenticated();
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(':'+ empAuth),
  }
};

const adminHeaders = () => {
  const adminAuth = getAuthenticated();
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(':'+ adminAuth),
  }
};

export { empHeaders, adminHeaders };