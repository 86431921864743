import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import JobsTab from './JobsTab';
import CalendarTab from './CalendarTab';


function AdminDashboard() {
  return (
    <Tabs defaultActiveKey="overview" id="admin-tabs" className="mb-3">
      <Tab eventKey="overview" title="Weekly Overview">
        <CalendarTab />
      </Tab>
      <Tab eventKey="jobs" title="Jobs">
        <JobsTab />
      </Tab>
    </Tabs>
  );
}

export default AdminDashboard;