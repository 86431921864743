import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { setAuthenticated, isAuthenticated, removeAuthenticated } from '../utils/Authentication'
import AdminDashboard from '../components/AdminDashboard';


function AdminLogin() {
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  // skip the login screen if they have entered the password previously
  useEffect(() => {
    if (isAuthenticated()) {
      setIsAdminAuthenticated(true);
    }
  }, []);

  const logout = () => {
    removeAuthenticated();
    setIsAdminAuthenticated(false);
  }

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    const data = { password: password };

    fetch('/api/login/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (res.ok) {
          setAuthenticated(password);
          setIsAdminAuthenticated(true);
        } else {
          setErrorMessage('Please double check your password.');
        }
      })
      .catch(error => {
        setErrorMessage(error.toString());
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      { isAdminAuthenticated && 
        <Button
        className='ml-5 mt-5'
          variant="outline-primary"
          onClick={() => logout()}
        >
          Logout
        </Button>
      }
      <Container> 
        <Row>
          <Col className='mt-5' sm={10} md={{ span: 6, offset: 3 }}>
            <h2>Admin Panel</h2>
            { isAdminAuthenticated ?
              <AdminDashboard />
            :
            <Form className='bordered' onSubmit={submit}>
              <Form.Group className="mb-3" controlId="formCode">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  onChange={e => setPassword(e.target.value)} 
                />
                <Form.Text className="text-muted">
                  Please reach out to the site admin if there are any problems.
                </Form.Text>
              </Form.Group>
              <Button disabled={isLoading} variant="primary" type="submit">
              {isLoading ? 
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                :
                <>Enter</>
              }
              </Button>
              <p>{errorMessage}</p>
            </Form>
          }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminLogin;